<template>
  <div class="yemian">
    <!-- <img src="../images/small404.png" alt="" /> -->
    <p>哎呀，找不到你要的页面啦</p>
    <span @click="$router.push({ path: '/' })">返回首页</span>
  </div>
</template>
<script>
export default {
  name: "venuebook",
  data() {
    return {};
  }
};
</script>
<style scope>
.yemian {
  width: 100%;
  height: 100vh;
  background-color: #f7efe4;
  text-align: center;
}
.yemian img {
  width: 40%;
  /* height: 50%; */
  /* background-size: 100% 100%; */
  margin-top: 16vh;
}
.yemian p {
  color: #e94e38;
  font-size: 14px;
  font-weight: 600;
}
.yemian span {
  display: inline-block;
  width: 100px;
  height: 35px;
  background-color: #eb4f39;
  margin-top: 8vh;
  left: calc(50% - 50px);
  text-align: center;
  color: #fff;
  line-height: 35px;
  border-radius: 16px;
  font-size: 13px;
}
</style>